import Jax from '../components/jax/jax';

document.addEventListener('ajax-form-success', (data) => {
  const { form } = data.detail;
  const checksForm = document.querySelector('[data-checks-form]');
  if (!form || !checksForm) {
    return;
  }

  if (form.dataset.ajaxForm === checksForm.dataset.ajaxForm) {
    const jax = new Jax(window.location.href);
    jax.send().then((html) => {
      const page = document.createElement('div');
      page.innerHTML = html;
      const newData = page.querySelector('[data-checks-list]');
      const currentData = document.querySelector('[data-checks-list]');
      // const newImages = page.querySelector('[data-checks-images]');
      // const currentImages = document.querySelector('[data-checks-images]');

      if (currentData) {
        currentData.parentNode.replaceChild(newData, currentData);
      }
      // if (currentImages) {
      //   currentImages.parentNode.replaceChild(newImages, currentImages);
      // }

      const event = new Event('DOMContentMutated');
      document.dispatchEvent(event);
    });
  }
});