import Jax from '../components/jax/jax';

function createItem() {
  const listElement = document.querySelector('[data-lottery-list]');
  const jax = new Jax('/lottery/checks', 'POST', true);
  listElement.innerHTML = '';
  jax.send().then((object) => {
    object.forEach((check) => {
      const item = document.createElement('li');
      let div = document.createElement('div');
      const number = Array.from(listElement.children).length + 1;
      item.classList.add('lottery-page__item');

      div.innerHTML = number;
      item.append(div);

      div = document.createElement('div');
      div.innerHTML = check.user;
      item.append(div);

      div = document.createElement('div');
      div.innerHTML = check.phone;
      item.append(div);

      div = document.createElement('div');
      div.innerHTML = check.date;
      item.append(div);

      listElement.append(item);
    });
  });
}

function initLottery() {
  const button = document.querySelector('[data-lottery-button]');
  if (button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      createItem();
    });
  }
}

document.addEventListener('DOMContentLoaded', initLottery);