import BrowserDetector from '../../components/browser-detector/browser-detector';

const getAndroidVersion = (ua) => {
  ua = (ua || navigator.userAgent).toLowerCase();
  const match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : 9999;
};

window.addEventListener('DOMContentLoaded', () => {
  const supported = new BrowserDetector().isSupported() && parseInt(getAndroidVersion(), 10) > 8;
  const checkRegisterForm = document.querySelector('[data-checks-form]');
  if (checkRegisterForm) {
    if (supported) {
      const oldUploadField = checkRegisterForm.querySelector('[data-old-upload]');
      if (oldUploadField) {
        // oldUploadField.style.display = 'none';
      }

      const newUploadField = checkRegisterForm.querySelector('[data-new-upload]');
      if (newUploadField) {
        newUploadField.style.display = 'block';
      }
    }
  }
});