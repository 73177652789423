require('../scss/app.scss');

window.noZensmooth = true;

require('./common/true-vh.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/check-browser');
// require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
// require('./common/need-animation.js');
require('./common/floating-field.js');

require('../components/accordion/accordion');
require('../components/fields/js/filesfield');
// require('../components/smart-tabs/smart-tabs');

require('./field/phone.js');
// require('./timer');
require('./hamburger');
require('./success-redirect');
require('./checks-form');
require('fslightbox');
require('./lottery');
require('../components/smart-tabs/smart-tabs');
require('../fonts/HelveticaNeue/stylesheet.css');
